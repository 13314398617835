<template>
  <ul class="widget-menu-panel" v-show="visible" id="widget-menu">
    <li class="widget-menu-item" v-for="(item, itemIndex) in menuList"
      @click="e => item.handler(selectText, e)" :key="item.name + itemIndex"
    >
      {{item.name}}
    </li>
  </ul>
</template>

<script>
export default {
  name: "RightWidgetMenu",
  mounted() {
    document.addEventListener('click', () => {
      this.visible = false;
    })
    this.initContextMenu();
  },
  props: {
    menuList: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      visible: false,
      selectText: '',
    }
  },
  methods: {
    initContextMenu() {
      document.querySelectorAll('iframe').forEach(iframe => {
        console.log(iframe.contentWindow, 'asdada');
        console.log(iframe.contentWindow.document.querySelector('.checkbox-control'), '获取到的DOM');
      })
      document.body.addEventListener('contextmenu', e => {
        e.preventDefault();
        const el = document.getElementById('widget-menu');
        if (window.innerHeight - e.clientY < el.clientHeight) {
          el.style.bottom = '10px';
          el.style.top = 'unset';
        } else {
          el.style.top = e.clientY + 'px';
          el.style.bottom = 'unset';
        }

        if (window.innerWidth - e.clientX < el.clientWidth) {
          el.style.right = '10px';
          el.style.left = 'unset';
        } else {
          el.style.left = e.clientX + 'px';
          el.style.right = 'unset';
        }
        this.selectText = window.getSelection().toString();
        this.visible = true;
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.widget-menu-panel
  position fixed
  background #fff
  min-width 200px
  border-radius 2px
  box-shadow 0 1px 16px 0 rgba(90, 109, 122, .3)
  cursor default
  list-style none
  text-align left
  z-index 9999
  li
    padding 10px
    &:hover
      background #e6e6e6
</style>